var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "py-5", attrs: { id: "social-section" } },
    [
      _c(
        "v-container",
        { staticClass: "pt-4" },
        [
          _c("div", { staticClass: "text-center" }, [
            _c("img", {
              staticClass: "mx-auto mb-4",
              attrs: {
                src: require("../assets/images/allcologo.png"),
                alt: "",
                height: "80",
              },
            }),
            _vm._v(" "),
            _c("h2", [
              _vm._v("The first truly centralized social care platform"),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "c2 mb-5" }, [
              _vm._v(
                "Allco was created hand-in-hand with CBOs to streamline workflow and bridge communication gaps in community care with a custom database of user-created and user-owned data."
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("v-row", [
            _c("div", { staticClass: "col-md-6 mb-4" }, [
              _c("div", { staticClass: "social-card" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-2" }, [
                    _c("img", {
                      staticClass: "d-inline-block",
                      attrs: {
                        src: require("../assets/images/icon_3.png"),
                        alt: "",
                        height: "50px",
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col" }, [
                    _c("h4", { staticClass: "d-inline-block" }, [
                      _vm._v("A single source of truth"),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "c2" }, [
                      _vm._v(
                        "Finally, a shared digital intake & referral system with a completely integrated resource directory."
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-6 mb-4" }, [
              _c("div", { staticClass: "social-card" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-2" }, [
                    _c("img", {
                      staticClass: "d-inline-block",
                      attrs: {
                        src: require("../assets/images/icon_2.png"),
                        alt: "",
                        height: "50px",
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col" }, [
                    _c("h4", { staticClass: "d-inline-block" }, [
                      _vm._v("Lose the silos, collaborate more."),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "c2" }, [
                      _vm._v(
                        "Now CBOs county-wide can eliminate overlap in\n                                daily operations and better cross communicate. "
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-6 mb-4" }, [
              _c("div", { staticClass: "social-card" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-2" }, [
                    _c("img", {
                      staticClass: "d-inline-block",
                      attrs: {
                        src: require("../assets/images/icon_1.png"),
                        alt: "",
                        height: "50px",
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col" }, [
                    _c("h4", { staticClass: "d-inline-block" }, [
                      _vm._v("Close the loops, bridge the gaps."),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "c2" }, [
                      _vm._v(
                        "Get real-time updates on community member status, eligibility, and even services received. "
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-6 mb-4" }, [
              _c("div", { staticClass: "social-card" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-2" }, [
                    _c("img", {
                      staticClass: "d-inline-block",
                      attrs: {
                        src: require("../assets/images/icon_0.png"),
                        alt: "",
                        height: "50px",
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col" }, [
                    _c("h4", { staticClass: "d-inline-block" }, [
                      _vm._v("Better data, better insights."),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "c2" }, [
                      _vm._v(
                        "Deeper community health insights create awareness, grant opportunities, and fuel optimized care."
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }