var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "py-3", attrs: { id: "resources-section" } },
        [
          _c("v-container", { staticClass: "pt-4" }, [
            _c("div", { staticClass: "text-center" }, [
              _vm.Resource.attributes.category
                ? _c("h1", { staticClass: "white-text" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.Resource.attributes.category.data.attributes.value
                        ) +
                        "\n        "
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "text-left", attrs: { cols: "4" } },
                [
                  _c("v-container", { staticClass: "content" }, [
                    _c(
                      "div",
                      { staticClass: "d-flex align-center" },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "whatsnewbtn",
                            on: { click: _vm.goBack },
                          },
                          [
                            _vm._v("GO BACK"),
                            _c("v-icon", {
                              staticClass: "mdi mdi-transfer-left",
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "8" } },
                [
                  _c("v-container", { staticClass: "content" }, [
                    _c("h2", [_vm._v(_vm._s(_vm.Resource.attributes.Title))]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "d-none d-md-flex",
                  attrs: { cols: "12", md: "4" },
                },
                [
                  _c(
                    "v-container",
                    { staticClass: "content" },
                    [_c("ResourcesNavRight", { attrs: { id: "sidebar" } })],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "8", lg: "8", xl: "8" } },
                [
                  _c("v-container", { staticClass: "content" }, [
                    _vm.Resource.attributes.video_link
                      ? _c("div", { staticClass: "text-center" }, [
                          _c("div", { staticClass: "embed-youtube" }, [
                            _c("iframe", {
                              attrs: {
                                width: "660",
                                height: "415",
                                src:
                                  "https://www.youtube.com/embed/" +
                                  _vm.Resource.attributes.video_link,
                                title: "YouTube video player",
                                frameborder: "0",
                                allow:
                                  "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
                                allowfullscreen: "",
                              },
                            }),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", [
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(_vm.Resource.attributes.body),
                              },
                            }),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }