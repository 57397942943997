var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "header" }, [
    _c(
      "nav",
      [
        _c(
          "v-container",
          [
            _vm.$route.path === "/about"
              ? _c(
                  "v-toolbar",
                  { attrs: { fixed: "" } },
                  [
                    _vm.$vuetify.breakpoint.smAndDown
                      ? _c(
                          "v-app-bar-nav-icon",
                          {
                            attrs: { "aria-label": "nav expand button" },
                            on: {
                              click: function ($event) {
                                _vm.drawer = !_vm.drawer
                              },
                            },
                          },
                          [
                            _c("v-icon", { attrs: { large: "" } }, [
                              _vm._v("mdi-menu"),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$vuetify.breakpoint.smAndDown
                      ? _c("v-spacer")
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "v-toolbar-title",
                      [
                        _c(
                          "router-link",
                          { attrs: { to: "/" } },
                          [
                            _c("v-img", {
                              attrs: {
                                src: require("../assets/wclogo.png"),
                                "max-height": _vm.wclogoMaxHeight,
                                contain: "",
                                justify: "center",
                                align: "center",
                                position: "inherit",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.$vuetify.breakpoint.mdAndUp ? _c("v-spacer") : _vm._e(),
                    _vm._v(" "),
                    _vm.$vuetify.breakpoint.mdAndUp
                      ? _c(
                          "v-toolbar-items",
                          { staticClass: "text-uppercase" },
                          [
                            _c("v-btn", { attrs: { to: "/press", text: "" } }, [
                              _vm._v(" Press "),
                            ]),
                            _vm._v(" "),
                            _c("v-btn", { attrs: { to: "/", text: "" } }, [
                              _vm._v(" The Allco Platform "),
                            ]),
                            _vm._v(" "),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  text: "",
                                  href: "https://forms.office.com/Pages/ResponsePage.aspx?id=xPr6w6RWmk-ijvekFK0W-JZ5QdoH2eVMpp-VPDYjpO1UNDRVUE9YWTZOMEJKVkU1WFlVMkZDNEJLQSQlQCN0PWcu",
                                  target: "_blank",
                                },
                              },
                              [_vm._v(" Contact ")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _c(
                  "v-toolbar",
                  { attrs: { fixed: "" } },
                  [
                    _vm.$vuetify.breakpoint.smAndDown
                      ? _c(
                          "v-app-bar-nav-icon",
                          {
                            attrs: { "aria-label": "nav expand button" },
                            on: {
                              click: function ($event) {
                                _vm.drawer = !_vm.drawer
                              },
                            },
                          },
                          [
                            _c("v-icon", { attrs: { large: "" } }, [
                              _vm._v("mdi-menu"),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$vuetify.breakpoint.smAndDown
                      ? _c("v-spacer")
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "v-toolbar-title",
                      [
                        _c(
                          "router-link",
                          { attrs: { to: "/" } },
                          [
                            _c("v-img", {
                              attrs: {
                                src: require("../assets/images/allcologo_0.png"),
                                "max-height": _vm.logoMaxHeight,
                                contain: "",
                                justify: "center",
                                align: "center",
                                position: "inherit",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.$vuetify.breakpoint.mdAndUp ? _c("v-spacer") : _vm._e(),
                    _vm._v(" "),
                    _vm.$vuetify.breakpoint.mdAndUp
                      ? _c(
                          "v-toolbar-items",
                          {
                            staticClass:
                              "align-center justify-center text-uppercase",
                          },
                          [
                            _c(
                              "v-btn",
                              { attrs: { to: "/pricing", text: "" } },
                              [_vm._v("Features & Pricing")]
                            ),
                            _vm._v(" "),
                            _c("v-btn", { attrs: { to: "/faqs", text: "" } }, [
                              _vm._v(" FAQ "),
                            ]),
                            _vm._v(" "),
                            _c("v-btn", { attrs: { to: "/about", text: "" } }, [
                              _vm._v(" wellconnected "),
                            ]),
                            _vm._v(" "),
                            _c(
                              "v-btn",
                              {
                                staticClass: "nav-buttons",
                                attrs: {
                                  color: "#f0bc5f",
                                  elevation: "2",
                                  small: "",
                                  href: "https://account.allco.app/registration/organization",
                                  target: "_blank",
                                },
                              },
                              [_vm._v("\n            Register\n          ")]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-btn",
                              {
                                staticClass: "nav-buttons",
                                attrs: {
                                  color: "#f0bc5f",
                                  elevation: "2",
                                  small: "",
                                  href: "https://account.allco.app/auth/login",
                                  target: "_blank",
                                },
                              },
                              [_vm._v("\n            Allco Login\n          ")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
            _vm._v(" "),
            _c(
              "v-navigation-drawer",
              {
                attrs: { absolute: "", temporary: "" },
                model: {
                  value: _vm.drawer,
                  callback: function ($$v) {
                    _vm.drawer = $$v
                  },
                  expression: "drawer",
                },
              },
              [
                _c(
                  "v-list",
                  { staticClass: "pt-0", attrs: { dense: "" } },
                  [
                    _c(
                      "v-list-item",
                      [
                        _c(
                          "v-list-item-content",
                          [
                            _c(
                              "v-list-item-title",
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "header-link",
                                    attrs: { to: "/" },
                                  },
                                  [_vm._v(" Home ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-list-item",
                      [
                        _c(
                          "v-list-item-content",
                          [
                            _c(
                              "v-list-item-title",
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "header-link",
                                    attrs: { to: "/about" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  About\n                "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-list-item",
                      [
                        _c(
                          "v-list-item-content",
                          [
                            _c(
                              "v-list-item-title",
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "header-link",
                                    attrs: { to: "/faqs" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  FAQs\n                "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-list-item",
                      [
                        _c(
                          "v-list-item-content",
                          [
                            _c(
                              "v-list-item-title",
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "header-link",
                                    attrs: { to: "/press" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  Press\n                "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-list-item",
                      {
                        attrs: {
                          href: "http://account.allco.app/auth/login",
                          target: "_blank",
                        },
                      },
                      [
                        _c(
                          "v-list-item-content",
                          [_c("v-list-item-title", [_vm._v(" Allco Login ")])],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }