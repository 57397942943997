import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import PressRelease from '../views/ArticleLayouts/PressRelease.vue';
import Resource from '../views/ArticleLayouts/Resource.vue';
import PressReleases from '../views/PressReleases.vue';
import ResourcesNav from '../components/ResourcesNav.vue';
import VueRouterBackButton from 'vue-router-back-button';


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/press',
    name: 'Press Releases',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: PressReleases
  },
  // {
  //   path: '/pressrelease/:id',
  //   name: 'Press Release',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: PressRelease
  // },
  {
    path: '/pressrelease/:slug',
    name: 'Press Release',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: PressRelease
  },
  {
    path: '/resource/:slug',
    name: 'Resource',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Resource
  },
  {
    path: '/resources',
    name: 'Resources',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "resources" */ '../views/ResourcesLanding.vue')
  },
  {
    path: '/resourcecategory',
    name: 'Resources Container',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "resources" */ '../views/Resources/ResourcesContainer.vue'),
    children: [
      {
        path: 'whatsnew',
        name: 'Whats New',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "whats new" */ '../views/Resources/WhatsNew.vue')
      },
      {
        path: 'guidedtutorial',
        name: 'Guided Tutorial',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "whats new" */ '../views/Resources/GuidedTutorial.vue')
      },
      {
        path: 'organizationadmin',
        name: 'Organization Admins',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "organization admins" */ '../views/Resources/OrganizationAdmin.vue')
      },
      {
        path: 'needsandreferrals',
        name: 'Needs and Referrals',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "organization admins" */ '../views/Resources/NeedsAndReferrals.vue')
      },
      {
        path: 'communitymemberprivacy',
        name: 'Community Member Privacy',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "organization admins" */ '../views/Resources/CommunityMemberPrivacy.vue')
      },
      {
        path: 'newtoallco',
        name: 'New To Allco',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "organization admins" */ '../views/Resources/NewToAllco.vue')
      },
      {
        path: 'userpreferences',
        name: 'User Preferences',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "user preferences" */ '../views/Resources/UserPreferences.vue')
      },
    ]
  },
  {
    path: '/FAQs',
    name: 'FAQs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "faqs" */ '../views/FAQs.vue')
  },
  {
    path: '/pricing',
    name: 'Pricing',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "pricing" */ '../views/Pricing.vue')
  },
  {
    path: '/training',
    name: 'Training',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "training" */ '../views/Training.vue')
  },
  {
    path: '/events',
    name: 'Events',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "training" */ '../views/Events.vue')
  },
  {
    path: '/allcoexplainer',
    name: 'Allco Video',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "training" */ '../views/AllcoVideo.vue')
  },

  {
    path: '/*',
    component: 404
  }
]



const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { x: 0, y: 0 }
  },
})



Vue.use(VueRouterBackButton, { router });

export default router
