var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "stats-feature" } },
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c("div", { staticClass: "text-center" }, [
            _c("h2", { staticClass: "mb-6" }, [
              _vm._v(
                "\n        Join the community and start making an impact with allco\n      "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12", md: "3" } }, [
                _c(
                  "div",
                  { staticClass: "third-bg stat-cards mb-2 text-center" },
                  [
                    _c("h2", [_vm._v("600+")]),
                    _vm._v(" "),
                    _c("h4", [_vm._v("active users and growing")]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("v-col", { attrs: { cols: "12", md: "3" } }, [
                _c(
                  "div",
                  { staticClass: "accent-1-bg text-center stat-cards mb-2" },
                  [
                    _c("h2", [_vm._v("10,000+")]),
                    _vm._v(" "),
                    _c("h4", [_vm._v("listed organizations")]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("v-col", { attrs: { cols: "12", md: "3" } }, [
                _c(
                  "div",
                  { staticClass: "secondary-bg text-center stat-cards mb-2" },
                  [
                    _c("h2", [_vm._v("30,000+")]),
                    _vm._v(" "),
                    _c("h4", [_vm._v("listed service locations")]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("v-col", { attrs: { cols: "12", md: "3" } }, [
                _c(
                  "div",
                  { staticClass: "accent-2-bg text-center stat-cards mb-2" },
                  [
                    _c("h2", [_vm._v("120+")]),
                    _vm._v(" "),
                    _c("h4", [_vm._v("healthcare partnerships")]),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }