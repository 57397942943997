// press release service
export default {
  install: (Vue) => {
    //private

    const qs = require('qs');
    const query = qs.stringify({
      populate: {
        category: {
          fields: ['value'],
        }
      },         
    }, {
      encodeValuesOnly: true,
    });
    const route = 'https://api.getwellconnected.co/api/resources';

    //public
    Vue.$resourcesService = {
      find(params) {
        return Vue.axios.get(`${route}?${query}`, {
          params: params
        });
      },

      findOne(key, params) {
        return Vue.axios.get(`${route}/${key}?${query}`, {
          params: params
        });
      },

      async findOneBySlug(slug, params) {
        var results = await Vue.axios.get(`${route}?${query}&filters[slug]=${slug}`, {
          params: params
        });
        console.log(results);
        return results.data.data[0]
      },
    };



  }
};