var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    { attrs: { id: "hero-section" } },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { staticClass: "py-4" },
            [
              _c(
                "v-col",
                { staticClass: "pl-5", attrs: { cols: "12", md: "6" } },
                [
                  _c("h1", [
                    _vm._v("Collaborative community care."),
                    _c("br"),
                    _vm._v("All in one place."),
                  ]),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticStyle: { color: "white", "margin-top": "5%" } },
                    [
                      _vm._v(
                        "\n          allco is the first centralized social care platform that is built to\n          streamline workflow processes for CBOs.\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "#f0bc5f",
                        elevation: "2",
                        rounded: "",
                        small: "",
                        href: "https://account.allco.app/registration/organization",
                        target: "_blank",
                      },
                    },
                    [_vm._v("Register Now")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticStyle: { "font-weight": "bold", color: "white" },
                      attrs: {
                        color: "#1cbaad",
                        elevation: "2",
                        rounded: "",
                        small: "",
                        to: "/events",
                        target: "_blank",
                      },
                    },
                    [_vm._v("Events")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                {
                  staticClass: "pl-5 d-none d-md-block",
                  attrs: { cols: "12", md: "6" },
                },
                [
                  _c("img", {
                    staticStyle: { "vertical-align": "middle" },
                    attrs: {
                      src: require("../assets/images/image1.png"),
                      alt: "",
                      width: "90%",
                    },
                  }),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }