var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "section-feature" } },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "d-none d-md-block pt-4",
                  attrs: { cols: "6", md: "4" },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("../assets/images/sweater.png"),
                      alt: "",
                      width: "100%",
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c("v-col", { attrs: { cols: "12", md: "8" } }, [
                _c("h2", { staticClass: "mb-6" }, [
                  _vm._v(
                    "\n                Hear it straight from our partners.\n              "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "partner-cards" }, [
                  _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _c("v-col", { attrs: { cols: "10", md: "10" } }, [
                        _c("p", { staticClass: "c2" }, [
                          _vm._v(
                            '\n                The platform is critical to maximizing health equities among\n                disenfranchised populations and general whole person care in\n                our communities. Overall community health will rise due to the\n                connectivity of organizations utilizing allco, collection and\n                sharing of community data and the closed loop referral system\n                it has in place"\n              '
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "fb" }, [
                          _vm._v("CBO CONSORTIUM OF UPSTATE NY"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("v-col", { attrs: { cols: "2", md: "1" } }, [
                        _c("i", {
                          staticClass: "mdi mdi-format-quote-close icn2",
                        }),
                      ]),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }