var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "section-feature" } },
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                _c("h2", { staticClass: "fb mb-4" }, [
                  _vm._v("Efficient, streamlined workflow. "),
                  _c("br"),
                  _vm._v(" Made for CBOs, by CBOs. "),
                ]),
                _vm._v(" "),
                _c("img", {
                  attrs: {
                    src: require("../assets/images/sweater.png"),
                    alt: "",
                    width: "100%",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                _c("div", { staticClass: "feature-card mb-4" }, [
                  _c("h4", { staticClass: "fb" }, [
                    _vm._v("Longitudinal Records"),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "c2" }, [
                    _vm._v(
                      "Shared records ensure a common reality of community members' activity throughout the social care continuum. "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "feature-card mb-4" }, [
                  _c("h4", { staticClass: "fb" }, [
                    _vm._v("Robust Service Directory"),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "c2" }, [
                    _vm._v(
                      "Always up-to-date directory is maintained by service orgs to ensure information is easily searchable and always current.  "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "feature-card mb-4" }, [
                  _c("h4", { staticClass: "fb" }, [
                    _vm._v("Support Coordination"),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "c2" }, [
                    _vm._v(
                      "Seamlessly access community member records makes coordinating support with other organizations easy and efficient. "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "feature-card mb-4" }, [
                  _c("h4", { staticClass: "fb" }, [
                    _vm._v("Registry Creation"),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "c2" }, [
                    _vm._v(
                      "All social care organizations have shared access for targeted outreach and in depth understanding of community needs."
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }