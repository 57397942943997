var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c("v-main", [
        _c(
          "div",
          { attrs: { id: "app" } },
          [
            _c("Navigation"),
            _vm._v(" "),
            _c("router-view"),
            _vm._v(" "),
            _c("Footer"),
            _vm._v(" "),
            _c(
              "div",
              [
                _c(
                  "v-container",
                  [
                    _c(
                      "v-row",
                      [
                        _c("v-col", [
                          _c("p", { staticClass: "c2 text-center" }, [
                            _vm._v(
                              "\n                We close the communication gaps by developing and reporting\n                community and individual measures while also educating data\n                owners regarding data literacy, technology accesibility, and\n                financial stability. © 2023 wellconnected Inc. All rights\n                reserved\n              "
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }