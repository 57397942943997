var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "demoCTA" },
    [
      _c(
        "v-container",
        { staticClass: "text-center" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pb-0", attrs: { cols: "12", md: "12" } },
                [
                  _c("div", { staticClass: "DemoText h1" }, [
                    _vm._v("Want to know more about allco?"),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "#fcc151",
                        href: "https://account.allco.app/registration/organization",
                        target: "_blank",
                        height: "42px",
                        "min-width": "150px",
                      },
                    },
                    [_vm._v("\n          Register\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        href: "https://forms.office.com/Pages/ResponsePage.aspx?id=xPr6w6RWmk-ijvekFK0W-GL7UpLnUkNJrNgobCyCXWJUNDRVUE9YWTZOMEJKVkU1WFlVMkZDNEJLQS4u",
                        target: "_blank",
                        height: "42px",
                        "min-width": "150px",
                      },
                    },
                    [_vm._v("\n          Let's Talk!\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }