var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-footer",
    { staticClass: "light-bg", attrs: { id: "wcFooter" } },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            {
              staticClass: "center-mobile",
              attrs: { align: "center", justify: "center" },
            },
            [
              _c(
                "v-col",
                { staticClass: "br br1", attrs: { cols: "12", md: "3" } },
                [
                  _c("router-link", { attrs: { to: "/pricing" } }, [
                    _vm._v("Features & Pricing"),
                  ]),
                  _vm._v(" "),
                  _c("router-link", { attrs: { to: "/faqs" } }, [
                    _vm._v("FAQs"),
                  ]),
                  _vm._v(" "),
                  _c("router-link", { attrs: { to: "/about" } }, [
                    _vm._v("Wellconnected"),
                  ]),
                  _vm._v(" "),
                  _c("router-link", { attrs: { to: "/press" } }, [
                    _vm._v("Press"),
                  ]),
                  _vm._v(" "),
                  _c("router-link", { attrs: { to: "/events" } }, [
                    _vm._v("Events"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      attrs: { href: "allco_version1_6.pdf", target: "_blank" },
                    },
                    [_vm._v("Allco Features")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "br br2", attrs: { cols: "12", md: "3" } },
                [
                  _c("p", { staticClass: "c2" }, [
                    _vm._v(
                      "\n          Want to learn about how your organization can deliver better\n          community care?\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "mt-2",
                      attrs: {
                        href: "https://forms.office.com/Pages/ResponsePage.aspx?id=xPr6w6RWmk-ijvekFK0W-JZ5QdoH2eVMpp-VPDYjpO1UNDRVUE9YWTZOMEJKVkU1WFlVMkZDNEJLQSQlQCN0PWcu",
                        target: "_blank",
                      },
                    },
                    [_vm._v("Let's talk")]
                  ),
                ]
              ),
              _vm._v(" "),
              _c("v-col", { attrs: { cols: "12", md: "3" } }, [
                _c("p", { staticClass: "c2" }, [
                  _vm._v("\n          247 Cayuga Rd "),
                  _c("br"),
                  _vm._v("\n          Buffalo, NY, 14225\n        "),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "c2" }, [
                  _vm._v("\n          Community Member "),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      attrs: { href: "privacypolicy621.pdf", target: "_blank" },
                    },
                    [_vm._v(" Privacy Policy")]
                  ),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "c2" }, [
                  _vm._v("\n          User\n          "),
                  _c(
                    "a",
                    { attrs: { href: "userterms.pdf", target: "_blank" } },
                    [_vm._v(" Terms and Conditions")]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "text-center", attrs: { cols: "12", md: "3" } },
                [
                  _c("img", {
                    staticClass: "img-fluid",
                    attrs: {
                      src: require("../assets/images/wellconnected_vert_positive.png"),
                      alt: "",
                      width: "100%",
                    },
                  }),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }