var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "py-5", attrs: { id: "social-section" } },
    [
      _c(
        "v-container",
        { staticClass: "pt-4" },
        [
          _c("div", { staticClass: "text-center" }, [
            _c("h2", [_vm._v("allco was created for CBOs, by CBOs")]),
            _vm._v(" "),
            _c("p", { staticClass: "c2 mb-3" }, [
              _vm._v(
                "\n        We worked directly with community-based organizations to create a\n        secure, central data hub that removes silos, allows cross\n        collaboration, and, ultimately, provides better social care services.\n      "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    { staticClass: "mt-3" },
                    [
                      _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                        _c(
                          "video",
                          {
                            attrs: {
                              width: "100%",
                              height: "auto",
                              controls: "",
                              controlslist: "play volume",
                              poster: require("../assets/images/graphics/slide 1.jpg"),
                            },
                          },
                          [
                            _c("source", {
                              attrs: {
                                src: require("../assets/allco_video_short.mp4"),
                                type: "video/mp4",
                              },
                            }),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c("h3", { staticClass: "mb-3" }, [
                            _vm._v("Platform features:"),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-2" }, [
                            _c("i", {
                              staticClass: "third-color mdi mdi-check mr-3",
                            }),
                            _vm._v(
                              " Shared digital\n              intake system\n            "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-2" }, [
                            _c("i", {
                              staticClass: "third-color mdi mdi-check mr-3",
                            }),
                            _vm._v(
                              " Shared referral\n              system\n            "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-2" }, [
                            _c("i", {
                              staticClass: "third-color mdi mdi-check mr-3",
                            }),
                            _vm._v(
                              " Integrated\n              resource directory\n            "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-2" }, [
                            _c("i", {
                              staticClass: "third-color mdi mdi-check mr-3",
                            }),
                            _vm._v(
                              " Cross-org\n              communication\n            "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-2" }, [
                            _c("i", {
                              staticClass: "third-color mdi mdi-check mr-3",
                            }),
                            _vm._v(
                              " Real-time status\n              updates\n            "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-2" }, [
                            _c("i", {
                              staticClass: "third-color mdi mdi-check mr-3",
                            }),
                            _vm._v(
                              " Better data,\n              better insights\n            "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { attrs: { align: "center", justify: "center" } },
                            [
                              _c("v-col", { attrs: { cols: "12", md: "5" } }, [
                                _c("img", {
                                  staticClass: "mt-3 d-none d-md-block",
                                  attrs: {
                                    src: require("../assets/images/allcologo.png"),
                                    alt: "",
                                    height: "60",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "7" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "#f0bc5f",
                                        elevation: "2",
                                        rounded: "",
                                        small: "",
                                        href: "https://forms.office.com/Pages/ResponsePage.aspx?id=xPr6w6RWmk-ijvekFK0W-JZ5QdoH2eVMpp-VPDYjpO1UNDRVUE9YWTZOMEJKVkU1WFlVMkZDNEJLQSQlQCN0PWcu",
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v("BOOK A DEMO")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }