<template>
  <div>
    <div id="pressheader-section" class="py-3">
      <v-container class="pt-4">
        <div class="text-center">
          <h1 class="white-text">{{ PressRelease.attributes.Headline }}</h1>
          <p class="white-text">
            <em> {{ PressRelease.attributes.Subheading }}</em>
          </p>
        </div>
      </v-container>
    </div>
    <v-container>
      <v-row>
        <v-col cols="12" lg="12" xl="12">
          <div>
            <div>
              <v-card flat color="transparent">
                <v-card-text>
                  <div class="d-flex align-center justify-space-between">
                    <div class="d-flex align-center">
                      <v-avatar color="accent" size="36">
                        <v-icon dark>mdi-map-marker</v-icon>
                      </v-avatar>
                      <div class="pl-2 text-body-1">Buffalo, NY</div>
                    </div>
                    <div class="pl-3 d-flex align-center">
                      <v-avatar color="accent" size="36">
                        <v-icon dark>mdi-newspaper</v-icon>
                      </v-avatar>

                      <div class="pl-2 text-body-1">
                        {{
                          moment(PressRelease.attributes.publishedAt).format(
                            "MMMM Do YYYY"
                          )
                        }}
                      </div>
                    </div>
                    <div class="d-flex align-center">
                      <v-btn class="whatsnewbtn" @click="goBack"
                        >GO BACK<v-icon class="mdi mdi-transfer-left"></v-icon
                      ></v-btn>
                    </div>
                  </div>

                  <v-divider class="my-4"></v-divider>
                  <span v-html="PressRelease.attributes.Body"></span>
                  <v-divider class="my-8"></v-divider>
                </v-card-text>
              </v-card>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div class="mt-5 mb-5">
      <hr style="margin-top: 0 !important" class="blue-divider" />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
var moment = require("moment");
import VueMarkdownIt from "vue-markdown-it";

export default {
  name: "pressRelease",
  components: {
    VueMarkdownIt,
  },
  filters: {},
  data() {
    return {
      PressRelease: {
        attributes: {},
      },
      PressReleases: [],
      moment: moment,
    };
  },
  async beforeRouteEnter(to, from, next) {
    try {
      var PressRelease = await Vue.$pressReleaseService.findOneBySlug(
        to.params.slug
      );

      return next((vm) => {
        vm.PressRelease = PressRelease;
      });
    } catch (err) {
      console.log(err);
      next(false);
    }
  },
  async beforeRouteUpdate(to, from, next) {
    try {
      this.PressRelease = await Vue.$pressReleaseService.findOnebySlug(
        to.params.slug
      );

      return next();
    } catch (err) {
      console.log(err);
      next(false);
    }
  },
};
</script>

<style scoped>
#pressheader-section {
  background-color: #1a4ab0;
}

#pressheader-section h2 {
  font-weight: 600;
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

#pressheader-section h4 {
  font-size: 1.4rem;
}

.whatsnewbtn {
  padding: 8px 25px;
  text-decoration: none !important;
  color: #ffffff;
  border: 1px solid #1a4ab0;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  font-size: 0.8rem !important;
  font-family: "Roboto", sans-serif;
  background-color: #1a4ab0 !important;
}
</style>
