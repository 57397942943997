var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        {
          staticClass: "mx-auto",
          style: { "border-radius": "0 30px" },
          attrs: { height: "auto", width: "100%" },
        },
        [
          _c(
            "v-navigation-drawer",
            { attrs: { width: "300", permanent: "" } },
            [
              _c(
                "v-list",
                { attrs: { nav: "" } },
                _vm._l(_vm.items, function (item) {
                  return _c(
                    "v-list-item",
                    {
                      key: item.title,
                      attrs: { link: "", to: item.to, href: item.href },
                    },
                    [
                      _c(
                        "v-list-item-action",
                        { style: { "margin-right": "0 !important" } },
                        [
                          _c("v-img", {
                            staticClass: "nav-icon",
                            attrs: {
                              src: require("../assets/images/icons/" +
                                item.image),
                              "max-height": "35",
                              contain: "",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "text-wrap" },
                            [_vm._v(_vm._s(item.title))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }