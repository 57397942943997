<template>
  <div class="header">
    <nav>
      <v-container>
        <v-toolbar fixed v-if="$route.path === '/about'">
          <v-app-bar-nav-icon
            v-if="$vuetify.breakpoint.smAndDown"
            @click="drawer = !drawer"
            aria-label="nav expand button"
          >
            <v-icon large>mdi-menu</v-icon>
          </v-app-bar-nav-icon>
          <v-spacer v-if="$vuetify.breakpoint.smAndDown"></v-spacer>
          <v-toolbar-title>
            <router-link to="/">
              <v-img
                :src="require('../assets/wclogo.png')"
                :max-height="wclogoMaxHeight"
                contain      
                justify="center"
                align="center"          
                position="inherit"
              /> </router-link
          ></v-toolbar-title>
          <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
          <v-toolbar-items v-if="$vuetify.breakpoint.mdAndUp" class="text-uppercase">
            <v-btn to="/press" text> Press </v-btn>
            <v-btn to="/" text> The Allco Platform </v-btn>
            <v-btn text href="https://forms.office.com/Pages/ResponsePage.aspx?id=xPr6w6RWmk-ijvekFK0W-JZ5QdoH2eVMpp-VPDYjpO1UNDRVUE9YWTZOMEJKVkU1WFlVMkZDNEJLQSQlQCN0PWcu" target="_blank"> Contact </v-btn>
            <!-- <v-btn href="http://account.allco.app/" target="_blank" text>
              Allco Login
            </v-btn> -->
          </v-toolbar-items>
        </v-toolbar>

        <v-toolbar fixed v-else>
          <v-app-bar-nav-icon
            v-if="$vuetify.breakpoint.smAndDown"
            @click="drawer = !drawer"
            aria-label="nav expand button"
          >
            <v-icon large>mdi-menu</v-icon>
          </v-app-bar-nav-icon>
          <v-spacer v-if="$vuetify.breakpoint.smAndDown"></v-spacer>
          <v-toolbar-title>
            <router-link to="/">
              <v-img
                :src="require('../assets/images/allcologo_0.png')"
                :max-height="logoMaxHeight"
                contain
                justify="center"
                align="center"     
                position="inherit"
              /> </router-link
          ></v-toolbar-title>
          <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
          <v-toolbar-items
      v-if="$vuetify.breakpoint.mdAndUp" class="align-center justify-center text-uppercase">
            <!-- <v-btn to="/" text> Home </v-btn> -->
            <v-btn to="/pricing" text>Features & Pricing</v-btn>
            <v-btn to="/faqs" text> FAQ </v-btn>
            <v-btn to="/about" text> wellconnected </v-btn>      
            <v-btn class="nav-buttons" color="#f0bc5f" elevation="2" small href="https://account.allco.app/registration/organization" target="_blank" >
              Register
            </v-btn>       
            <v-btn class="nav-buttons" color="#f0bc5f" elevation="2" small href="https://account.allco.app/auth/login" target="_blank" >
              Allco Login
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <!--MOBILE NAVIGATION-->
        <v-navigation-drawer v-model="drawer" absolute temporary>
          <v-list class="pt-0" dense>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <router-link class="header-link" to="/"> Home </router-link>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <router-link class="header-link" to="/about">
                    About
                  </router-link>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <router-link class="header-link" to="/faqs">
                    FAQs
                  </router-link>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <router-link class="header-link" to="/press">
                    Press
                  </router-link>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item href="http://account.allco.app/auth/login" target="_blank">
              <v-list-item-content>
                <v-list-item-title> Allco Login </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </v-container>
    </nav>
  </div>
</template>


<script>
export default {
  name: "navigation",
  data: () => ({
    drawer: false,
    // dialog: true,
  }),
  computed: {
    logoMaxHeight() {
      return this.$vuetify.breakpoint.mdAndUp ? 60 : 42;
    },
    wclogoMaxHeight() {
      return this.$vuetify.breakpoint.mdAndUp ? 45 : 42;
    }
  },
};
</script>

<style>
@media only screen and (max-width: 600px) {
  .title-logo .logo {
    width: 225px;
  }
}
.logo {
  margin-top: 10px;
  max-height: 64px;
}
.title-logo {
  margin-left: 0px;
}
a.header-link,
a.header-link:visited {
  color: #676767;
  text-decoration: none;
}
.become-partner-link {
  color: white !important;
  background-color: #167e7e !important;
  border: 2px solid #57b8be !important;
  height: 36px !important;
}

.noshadow {
  box-shadow: none !important;
}
</style>

