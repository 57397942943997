var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "py-3", attrs: { id: "pressheader-section" } },
      [
        _c("v-container", { staticClass: "pt-4" }, [
          _c("div", { staticClass: "text-center" }, [
            _c("h1", { staticClass: "white-text" }, [_vm._v("Press Room")]),
            _vm._v(" "),
            _c("p", { staticClass: "white-text" }, [
              _c("em", [
                _vm._v("wellconnected Company News, Awards, & Media Resources"),
              ]),
            ]),
          ]),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      [
        _c(
          "v-container",
          [
            _c(
              "v-row",
              [
                _c("v-col", { attrs: { cols: "12", md: "6", lg: "8" } }, [
                  _c("div", [_c("h2", [_vm._v("Press Releases")])]),
                  _vm._v(" "),
                  _c(
                    "div",
                    _vm._l(_vm.PressReleases, function (PressRelease) {
                      return _c(
                        "div",
                        { key: PressRelease.attributes.slug },
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "Press Release",
                                  params: {
                                    slug: PressRelease.attributes.slug,
                                  },
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(PressRelease.attributes.Headline) +
                                  "\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm
                                    .moment(PressRelease.attributes.publishedAt)
                                    .format("MMMM Do YYYY")
                                ) +
                                "\n              "
                            ),
                          ]),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c("div", { staticClass: "pt-4" }, [
                    _c("h2", [_vm._v("wellconnected In The News")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      _vm._l(_vm.Coverages, function (Coverage) {
                        return _c("div", { key: Coverage.attributes.id }, [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: Coverage.attributes.Link,
                                target: "blank",
                              },
                            },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(Coverage.attributes.Headline) +
                                  "\n                "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              "\n                  " +
                                _vm._s(
                                  _vm
                                    .moment(Coverage.attributes.Date)
                                    .format("MMMM Do YYYY")
                                ) +
                                "\n                  - " +
                                _vm._s(Coverage.attributes.Outlet) +
                                "\n                "
                            ),
                          ]),
                        ])
                      }),
                      0
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("v-col", { attrs: { cols: "12", md: "6", lg: "4" } }, [
                  _c("div", [
                    _c("h2", [_vm._v("Media Contact")]),
                    _vm._v(" "),
                    _c("p", [
                      _c("em", [_vm._v("Public Relations, WellConnected")]),
                      _c("br"),
                      _vm._v("\n              Email:\n              "),
                      _c(
                        "a",
                        { attrs: { href: "mailto:info@getwellconnected.co" } },
                        [_vm._v("info@getwellconnected.co")]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("h2", [_vm._v("Downloads")]),
                    _vm._v(" "),
                    _c("p", [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "allco_brandelements.pdf",
                            target: "_blank",
                          },
                        },
                        [_vm._v("WellConnected Brand Guide")]
                      ),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c("a", { attrs: { href: "allcoLogos.zip" } }, [
                        _vm._v("allco Logos"),
                      ]),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c("a", { attrs: { href: "wellconnectedLogos.zip" } }, [
                        _vm._v("WellConnected Logos"),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("h2", [_vm._v("Follow Us On Social Media")]),
                    _vm._v(" "),
                    _c("div", [
                      _c(
                        "a",
                        {
                          staticStyle: { display: "inline-block" },
                          attrs: {
                            href: "https://twitter.com/connectedWNY",
                            target: "_blank",
                          },
                        },
                        [
                          _c("v-img", {
                            attrs: {
                              src: require("../assets/images/icons/twitter.png"),
                              "max-height": "40",
                              "max-width": "40",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticStyle: { display: "inline-block" },
                          attrs: {
                            href: "https://www.linkedin.com/company/wellconnected-llc",
                            target: "_blank",
                          },
                        },
                        [
                          _c("v-img", {
                            attrs: {
                              src: require("../assets/images/icons/linkedin.png"),
                              "max-height": "40",
                              "max-width": "40",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }