var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "section-leads" } },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pt-5", attrs: { cols: "12", md: "6" } },
                [
                  _c("h1", { staticClass: "fb mb-3" }, [
                    _vm._v("A wholistic approach to community care"),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n          With allco, a community member creates a\n          "
                    ),
                    _c("b", [_vm._v("single source intake record")]),
                    _vm._v(
                      ", eliminating redundant steps for\n          both the CBO and the community member.\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v("\n          Organizations can provide "),
                    _c("b", [_vm._v("targeted outreach")]),
                    _vm._v(", and easily\n          "),
                    _c("b", [_vm._v("close the loop on referrals.")]),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n          Most importantly, allco enables the ability to\n          "
                    ),
                    _c("b", [_vm._v("measure results")]),
                    _vm._v(", see what is working, and\n          "),
                    _c("b", [_vm._v("get grant funding")]),
                    _vm._v(
                      " to offer better community care services to\n          that member.\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "#f0bc5f",
                        elevation: "2",
                        rounded: "",
                        small: "",
                        href: "https://forms.office.com/Pages/ResponsePage.aspx?id=xPr6w6RWmk-ijvekFK0W-JZ5QdoH2eVMpp-VPDYjpO1UNDRVUE9YWTZOMEJKVkU1WFlVMkZDNEJLQSQlQCN0PWcu",
                        target: "_blank",
                      },
                    },
                    [_vm._v("BOOK A DEMO")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                _c("img", {
                  attrs: {
                    src: require("../assets/images/infographic.png"),
                    alt: "",
                    width: "100%",
                  },
                }),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }