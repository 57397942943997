import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify'
Vue.use(Vuetify)
const opts = {
  icons: {
      iconfont: 'mdi'
  },
  theme: {
      themes: {
        light: {
          primary: '#1648AF',
          secondary: '#1648AF',
          accent: '#1CBBAD',
        }
      }
    }
  };
const VBtn = Vue.component('VBtn')
VBtn.options.props.ripple.default = false
export default new Vuetify(opts)