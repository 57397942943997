var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "section-leads" } },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pt-5", attrs: { cols: "12", md: "6" } },
                [
                  _c("h1", { staticClass: "fb mb-3" }, [
                    _vm._v(
                      "\n          CBO cross coordination leads to better community care.\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _c("i", { staticClass: "mdi mdi-check-bold mr-3" }),
                    _vm._v(
                      " Community members inputted\n          one time only\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _c("i", { staticClass: "mdi mdi-check-bold mr-3" }),
                    _vm._v(
                      " Cross-agency access +\n          connection\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _c("i", { staticClass: "mdi mdi-check-bold mr-3" }),
                    _vm._v(
                      " No silos or duplicate steps\n          for CBOs\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _c("i", { staticClass: "mdi mdi-check-bold mr-3" }),
                    _vm._v(
                      " Improve time and cost\n          efficiency\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _c("i", { staticClass: "mdi mdi-check-bold mr-3" }),
                    _vm._v(
                      " More effective and wholistic\n          data\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _c("i", { staticClass: "mdi mdi-check-bold mr-3" }),
                    _vm._v(
                      " Easier reporting for grant\n          milestones\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("img", {
                    staticClass: "mt-3 d-none d-md-block",
                    attrs: {
                      src: require("../assets/images/allcologo.png"),
                      alt: "",
                      height: "60",
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                _c("img", {
                  staticClass: "ml-md-n15",
                  attrs: {
                    src: require("../assets/images/infographic.png"),
                    alt: "",
                    width: "100%",
                  },
                }),
                _vm._v(" "),
                _c("img", {
                  staticClass: "mt-3 d-md-none",
                  attrs: {
                    src: require("../assets/images/allcologo.png"),
                    alt: "",
                    height: "60",
                  },
                }),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }