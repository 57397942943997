<template>
  <div class="home text-center text-md-left">
    <Header />
    <!-- <CallToAction/> -->
    <CreatedCBOs />
    <StatisticsBar />
    <WholisticApproach />
    <Testimonials />
    <AboutWellconnected />

    <!-- <v-dialog
      content-class="noshadow"
      v-model="dialog"
      max-width="650"
      min-width="450"
      overlay-opacity=".75"
      overlay-color="#000000"
      style="box-shadow: none"
    >    
      <v-card color="#1a4ab0" shaped>
        <v-card-title class="text-h5">
          <v-img
            max-height="150"
            max-width="250"
            style="margin: 0 auto"
            :src="require('../assets/images/allco_reversed_notagline.png')"
          ></v-img>
        </v-card-title>

        <v-card-text class="text-center text-white" style="font-size: 1rem">
          <v-container>
            <p>
              Throughout the year, wellconnected will be holding educational and
              training events for the allco platform.
            </p>
            <p>
              Check out our events page to learn about all of the events
              wellconnected is holding.
            </p>
            <v-btn
              color="#1cbbad"
              href="/events"
              target="_blank"
              class="text-white"
              style="margin-bottom: 16px"
            >
              <p style="margin-bottom: 0 !important">ALLCO EVENTS</p>
            </v-btn>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog> -->
  </div>
</template>

<script>
// @ is an alias to /src
//import Skills from '@/components/Skills.vue'
import Header from "../components/Header.vue";
import SectionOne from "../components/Section_One.vue";
import SectionTwo from "../components/Section_Two.vue";
import SectionThree from "../components/Section_Three.vue";
import Testimonials from "../components/Testimonials.vue";
import "../assets/style.scss";
import WholisticApproach from "../components/WholisticApproach.vue";
import CreatedCBOs from "../components/CreatedByCBOSExplainer.vue";
import StatisticsBar from "../components/StatisticsBar.vue";
import AboutWellconnected from "../components/AboutWellconnected.vue";
import CallToAction from "../components/CallToAction.vue";

export default {
  name: "Home",
  data: () => ({
    drawer: false,
    dialog: true,
  }),
  components: {
    Header,
    SectionOne,
    SectionTwo,
    SectionThree,
    Testimonials,
    WholisticApproach,
    CreatedCBOs,
    StatisticsBar,
    AboutWellconnected,
    CallToAction,
  },
};
</script>
