<template>
  <div>
    <div id="pressheader-section" class="py-3">
      <v-container class="pt-4">
        <div class="text-center">
          <h1 class="white-text">Press Room</h1>
          <p class="white-text">
            <em>wellconnected Company News, Awards, & Media Resources</em>
          </p>
        </div>
      </v-container>
    </div>
    <div>
      <v-container>
        <v-row>
          <v-col cols="12" md="6" lg="8">
            <div>
              <h2>Press Releases</h2>
            </div>
            <div>
              <div
                v-for="PressRelease in PressReleases"
                :key="PressRelease.attributes.slug"
              >
                <router-link
                  :to="{
                    name: 'Press Release',
                    params: { slug: PressRelease.attributes.slug },
                  }"
                >
                  {{ PressRelease.attributes.Headline }}
                </router-link>
                <div>
                  {{
                    moment(PressRelease.attributes.publishedAt).format(
                      "MMMM Do YYYY"
                    )
                  }}
                </div>
              </div>
            </div>
            <hr />
            <div class="pt-4">
              <h2>wellconnected In The News</h2>

              <div>
                <div
                  v-for="Coverage in Coverages"
                  :key="Coverage.attributes.id"
                >
                  <a :href="Coverage.attributes.Link" target="blank">
                    {{ Coverage.attributes.Headline }}
                  </a>

                  <p>
                    {{
                      moment(Coverage.attributes.Date).format("MMMM Do YYYY")
                    }}
                    - {{ Coverage.attributes.Outlet }}
                  </p>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <div>
              <h2>Media Contact</h2>

              <p>
                <em>Public Relations, WellConnected</em><br />
                Email:
                <a href="mailto:info@getwellconnected.co"
                  >info@getwellconnected.co</a
                >
              </p>
            </div>

            <div>
              <h2>Downloads</h2>
              <p>
                <a href="allco_brandelements.pdf" target="_blank"
                  >WellConnected Brand Guide</a
                >
                <br />
                <a href="allcoLogos.zip">allco Logos</a>
                <br />
                <a href="wellconnectedLogos.zip">WellConnected Logos</a>
              </p>
            </div>

            <div>
              <h2>Follow Us On Social Media</h2>
              <div>
                <a
                  href="https://twitter.com/connectedWNY"
                  target="_blank"
                  style="display: inline-block"
                >
                  <v-img
                    :src="require('../assets/images/icons/twitter.png')"
                    max-height="40"
                    max-width="40"
                  ></v-img>
                </a>

                <a
                  href="https://www.linkedin.com/company/wellconnected-llc"
                  target="_blank"
                  style="display: inline-block"
                >
                  <v-img
                    :src="require('../assets/images/icons/linkedin.png')"
                    max-height="40"
                    max-width="40"
                  ></v-img>
                </a>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
var moment = require("moment");
import VueMarkdownIt from "vue-markdown-it";
import _ from "lodash";

export default {
  name: "pressReleases",
  components: {
    VueMarkdownIt,
  },
  filters: {},
  data() {
    return {
      PressRelease: [],
      PressReleases: [],
      Coverage: [],
      Coverages: [],
      moment: moment,
    };
  },
  async beforeRouteEnter(to, from, next) {
    try {
      var PressReleases = (
        await Vue.$pressReleaseService.find(to.params.id)
      ).data.data.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.attributes.publishedAt) - new Date(a.attributes.publishedAt);
      });
      // var Coverages = await Vue.$coverageService.find(to.params.id);
      
      var Coverages = (
        await Vue.$coverageService.find(to.params.id)
      ).data.data.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.attributes.publishedAt) - new Date(a.attributes.publishedAt);
      });

      return next((vm) => {
        vm.PressReleases = PressReleases;
        vm.Coverages = Coverages;
      });
    } catch (err) {
      console.log(err);
      next(false);
    }
  },
  //   computed: {
  //   orderedPressReleases: function () {
  //     return _.sortBy(this.PressReleases, 'publishedAt', 'desc');
  //   }
  // },
};
</script>

<style scoped>
#pressheader-section {
  background-color: #1a4ab0;
}

#pressheader-section h2 {
  font-weight: 600;
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

#pressheader-section h4 {
  font-size: 1.4rem;
}
</style>
