<template>
  <v-app>
    <v-main>
      <div id="app">
        <Navigation />
        <router-view />
        <Footer />
        <div>
          <v-container>
            <v-row>
              <v-col>
                <p class="c2 text-center">
                  We close the communication gaps by developing and reporting
                  community and individual measures while also educating data
                  owners regarding data literacy, technology accesibility, and
                  financial stability. &copy; 2023 wellconnected Inc. All rights
                  reserved
                </p>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import Navigation from "./components/Navigation.vue";
import Footer from "./components/Footer.vue";
export default {
  name: "app",
  data: () => ({
    drawer: false,
    // dialog: true,
  }),
  components: {
    Footer,
    Navigation,
  },
  computed: {
    logoMaxHeight() {
      return this.$vuetify.breakpoint.mdAndUp ? 50 : 42;
    },
  },
};
</script>