<template>
  <div>
    <v-card
      height="auto"
      width="100%"
      class="mx-auto"
      elevation="1"
      v-bind:style="{ 'border-radius': '0px 30px' }"
    >
      <v-navigation-drawer width="300" permanent>
        <v-list nav>
          <v-list-item
            v-for="item in items"
            :key="item.title"
            link
            :to="item.to"
            :href="item.href"
          >
            <v-list-item-action
              v-bind:style="{ 'margin-right': '0 !important' }"
            >
              <v-img
                class="nav-icon"
                :src="require('../assets/images/icons/' + item.image)"                
                max-height="35"
                contain
              />
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="text-wrap">{{
                item.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-card>
  </div>
</template>

<style lang="css">
.v-navigation-drawer__border {
  width: 0;
}

/* .v-navigation-drawer {
        width: inherit !important;
    } */

.v-list-item__title {
  color: #1a4ab0;
}
</style>

<script>
export default {
  data() {
    return {
      items: [
          {
          title: "New To allco?",
          icon: "mdi-help-box",
          to: "../resourcecategory/newtoallco",
          image: "allco_icon_small.png",
        },
        {
          title: "What's New",
          icon: "mdi-help-box",
          to: "../resourcecategory/whatsnew",
          image: "small_whatsnew.png",
        },
        {
          title: "Guided Tutorial",
          icon: "mdi-help-box",
          to: "../resourcecategory/guidedtutorial",
          image: "small_programs.png",
        },
        {
          title: "Community Member Privacy",
          icon: "mdi-image",
          to: "../resourcecategory/communitymemberprivacy",
          image: "small_community.png",
        },
        {
          title: "Needs & Referral",
          icon: "mdi-view-dashboard",
          to: "../resourcecategory/needsandreferrals",
          image: "small_referral.png",
        },
        {
          title: "Organization Admins",
          icon: "mdi-help-box",
          to: "../resourcecategory/organizationadmin",
          image: "small_admin.png",
        },
        {
          title: "User Manual",
          icon: "mdi-help-box",
          target: "_blank",
          href: "../allco_UserManual.pdf",
          image: "small_manual.png",
        },
      ],
      right: null,
    };
  },
};
</script>
