// press release service
export default {
    install: (Vue) => {
      //private
      const route = 'https://api.getwellconnected.co/api/coverages';
  
      //public
      Vue.$coverageService = {
        find(params) {
          return Vue.axios.get(route, {
            params: params
          });
        },

        findOne(key, params) {
          return Vue.axios.get(`${route}/${key}`, {
            params: params
          });
        },
      };

      
    }
  };