var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "text-white", attrs: { id: "about_support" } },
    [
      _c(
        "v-container",
        [
          _c("v-row", [
            _c("div", { staticClass: "col-md-5" }, [
              _c("img", {
                attrs: {
                  src: require("../assets/images/wellconnected_reversed.png"),
                  alt: "",
                  width: "",
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-7" }, [
              _c("p", [
                _vm._v(
                  "\n            wellconnected is the team behind allco. We are a purpose driven\n            organization with a mission to fundamentally transform the landscape\n            of health & social care by changing the way communities share\n            resources and information.\n          "
                ),
              ]),
              _vm._v(" "),
              _c(
                "p",
                [
                  _c(
                    "router-link",
                    { staticClass: "aboutWC-link", attrs: { to: "/about" } },
                    [
                      _vm._v(
                        "\n              LEARN MORE ABOUT WELLCONNECTED\n            "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }