<template>
  <div>
    <div id="resources-section" class="py-3">
      <v-container class="pt-4">
        <div class="text-center">
          <h1 v-if="Resource.attributes.category" class="white-text">
            {{ Resource.attributes.category.data.attributes.value }}
          </h1>
        </div>
      </v-container>
    </div>
    <v-container>
      <v-row>
        <v-col class="text-left" cols="4">
          <v-container class="content">
            <div class="d-flex align-center">
              <v-btn class="whatsnewbtn" @click="goBack"
                >GO BACK<v-icon class="mdi mdi-transfer-left"></v-icon
              ></v-btn>
            </div>
          </v-container>
        </v-col>
        <v-col cols="8">
          <v-container class="content">
            <h2>{{ Resource.attributes.Title }}</h2>
          </v-container>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4" class="d-none d-md-flex">
          <v-container class="content">
            <ResourcesNavRight id="sidebar" />
          </v-container>
        </v-col>
        <v-col cols="12" md="8" lg="8" xl="8">
          <v-container class="content">
            <div v-if="Resource.attributes.video_link" class="text-center">
              <div class="embed-youtube">
                <iframe
                  width="660"
                  height="415"
                  :src="'https://www.youtube.com/embed/' + Resource.attributes.video_link"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </v-container>
          <v-container>
            <v-row>
              <v-col>
               <span v-html="Resource.attributes.body"></span>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Vue from "vue";
var moment = require("moment");
import VueMarkdownIt from "vue-markdown-it";
import ResourcesNavRight from "../../components/ResourcesNavRight.vue";

export default {
  name: "Resource",
  components: {
    VueMarkdownIt,
    ResourcesNavRight,
  },
  filters: {},
  data() {
    return {
      Resource: {
        attributes: {}
      },
      moment: moment,
    };
  },
  async beforeRouteEnter(to, from, next) {
    try {
      var Resource = await Vue.$resourcesService.findOneBySlug(to.params.slug);

      return next((vm) => {
        vm.Resource = Resource;
      });
    } catch (err) {
      console.log(err);
      next(false);
    }
  },
  async beforeRouteUpdate(to, from, next) {
    try {
      this.Resource = await Vue.$resourcesService.findOneBySlug(to.params.slug);

      return next();
    } catch (err) {
      console.log(err);
      next(false);
    }
  },
};
</script>

<style scoped>
#resources-section {
  background-color: #1a4ab0;
}

#resources-section h2 {
  font-weight: 600;
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

#resources-section h4 {
  font-size: 1.4rem;
}

.social-card-button {
  padding: 20px;
  background: white;
  border: 1px solid #dadada;
  border-radius: 0px 40px 0px 40px;
  -webkit-border-radius: 0px 40px 0px 40px;
  -moz-border-radius: 0px 40px 0px 40px;
  -ms-border-radius: 0px 40px 0px 40px;
  -o-border-radius: 0px 40px 0px 40px;
}
.social-card-button h4 {
  font-weight: 600;
}

.embed-youtube {
  position: relative;
  padding-bottom: 56.25%; /* - 16:9 aspect ratio (most common) */
  /* padding-bottom: 62.5%; - 16:10 aspect ratio */
  /* padding-bottom: 75%; - 4:3 aspect ratio */
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.embed-youtube iframe,
.embed-youtube object,
.embed-youtube embed {
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.whatsnewbtn {
  padding: 8px 25px;
  text-decoration: none !important;
  color: #ffffff;
  border: 1px solid #1a4ab0;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  font-size: 0.8rem !important;
  font-family: "Roboto", sans-serif;
  background-color: #1a4ab0 !important;
}
</style>
