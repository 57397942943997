var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "py-3", attrs: { id: "pressheader-section" } },
        [
          _c("v-container", { staticClass: "pt-4" }, [
            _c("div", { staticClass: "text-center" }, [
              _c("h1", { staticClass: "white-text" }, [
                _vm._v(_vm._s(_vm.PressRelease.attributes.Headline)),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "white-text" }, [
                _c("em", [
                  _vm._v(" " + _vm._s(_vm.PressRelease.attributes.Subheading)),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12", lg: "12", xl: "12" } }, [
                _c("div", [
                  _c(
                    "div",
                    [
                      _c(
                        "v-card",
                        { attrs: { flat: "", color: "transparent" } },
                        [
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex align-center justify-space-between",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex align-center" },
                                    [
                                      _c(
                                        "v-avatar",
                                        {
                                          attrs: {
                                            color: "accent",
                                            size: "36",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { dark: "" } },
                                            [_vm._v("mdi-map-marker")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "pl-2 text-body-1" },
                                        [_vm._v("Buffalo, NY")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "pl-3 d-flex align-center" },
                                    [
                                      _c(
                                        "v-avatar",
                                        {
                                          attrs: {
                                            color: "accent",
                                            size: "36",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { dark: "" } },
                                            [_vm._v("mdi-newspaper")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "pl-2 text-body-1" },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm
                                                  .moment(
                                                    _vm.PressRelease.attributes
                                                      .publishedAt
                                                  )
                                                  .format("MMMM Do YYYY")
                                              ) +
                                              "\n                    "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "d-flex align-center" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "whatsnewbtn",
                                          on: { click: _vm.goBack },
                                        },
                                        [
                                          _vm._v("GO BACK"),
                                          _c("v-icon", {
                                            staticClass:
                                              "mdi mdi-transfer-left",
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("v-divider", { staticClass: "my-4" }),
                              _vm._v(" "),
                              _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.PressRelease.attributes.Body
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _c("v-divider", { staticClass: "my-8" }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mt-5 mb-5" }, [
      _c("hr", {
        staticClass: "blue-divider",
        staticStyle: { "margin-top": "0 !important" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }