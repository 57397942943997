// press release service
export default {
    install: (Vue) => {
      //private
      const route = 'https://api.getwellconnected.co/api/press-releases';
  
      //public
      Vue.$pressReleaseService = {
        find(params) {
          return Vue.axios.get(route, {
            params: params
          });
        },

        findOne(key, params) {
          return Vue.axios.get(`${route}/${key}`, {
            params: params
          });
        },

        async findOneBySlug(slug, params) {
          var results = await Vue.axios.get(`${route}?filters[slug]=${slug}`, {
            params: params
          });
          console.log(results);
          return results.data.data[0]
        },


        
      };

      
    }
  };