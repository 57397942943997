import 'vuetify/dist/vuetify.min.css';
import './assets/allco_UserManual.pdf';
import './assets/allco_companyprofile_UserManual.pdf';
import './assets/wellconnected_brandbook.pdf';
import './assets/allco_brandelements.pdf';
import './assets/wellconnectedprivacypolicy.pdf';
import './assets/allco_info_organizations.pdf';
import './assets/privacypolicy621.pdf';
import './assets/userterms.pdf';
import './assets/allco_version1_6.pdf';
import './assets/favicon.png';
import './assets/allco_video_short.mp4';
import './assets/allco_video_long.mp4';
import './assets/images/211allco.png';
import './assets/wellconnectedLogos.zip';
import './assets/allcoLogos.zip';
import Vue from 'vue';
import App from './App.vue';
import vuetify from '../plugins/vuetify';
import router from './router';
import VueGtag from "vue-gtag";
import './assets/style.scss';
import './assets/faqs.scss';
import './assets/about.scss';
import './assets/pricing.scss'

import PressReleaseService from './services/pressReleaseService';
import ResourcesService from './services/resourcesService';
import CategoryService from './services/categoryService';
import CoverageService from './services/coverageService';

import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.config.productionTip = false

Vue.use(VueAxios,axios);
Vue.use(PressReleaseService);
Vue.use(ResourcesService);
Vue.use(CategoryService);
Vue.use(CoverageService);

Vue.use(VueGtag, {
  config: { id: 'G-CZ09H0F4NK' }
}, router);

Vue.mixin({
  methods: {
    goBack: function () {
      this.$router.go(-1);
    },
  },
})

new Vue({
  el: '#app',
  router,
  vuetify,  
  template: '<App/>',
  components: {
    App,
  },
  methods: {
   
  },
  
});
